import { Route } from "react-router-dom";

function RouteLayoutNone ({ component: Component, componentProps = {}, ...rest }) {
    return (
        <Route
            {...rest}
            render={routeProps => (
                <Component {...routeProps} {...componentProps}/>
            )}
        />
    );
}

export default RouteLayoutNone;