import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton, InputAdornment,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import AuthService from '../../Services/AuthService';
import Events from '../../Utility/Events';
import PasswordRequirements from '../../Utility/PasswordRequirements';
import history from '../../Utility/RouterHistory';
import CheckLabel from '../Elements/CheckLabel';
import PasswordCreateField from '../Elements/PasswordCreateField';

import { isValidHttpUrl } from '../../Services/RestAppService.js';
import { getDateOnly } from '../../Utility/Formatter';
import { AuthContext } from '../Contexts/AuthContext';
import { CustomThemeContext } from '../Contexts/CustomThemeContext.js';
import AdvancedDatepicker from '../Elements/AdvancedDatepicker.js';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText';
import ContactTypemapComponent from '../../Utility/ContactTypemapComponent.js';
import AutoCompleteVirtualizedList from '../Secured/Lists/AutoCompleteVirtualizedList.js';

const useStyles = (theme) => ({
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
        flexGrow: 1,
        overflow: 'hidden',
    },
    spacer: {
        marginTop: theme.spacing(1),
    },
    spaceActions: {
        marginTop: theme.spacing(4),
    }
});

const createFields = (available = [], mandatory = []) => {
    let fields = [];
    if (!available.includes('lastname')) {
        available.unshift('lastname');
        mandatory.unshift('lastname');
    }
    if (!available.includes('firstname')) {
        available.unshift('firstname');
        mandatory.unshift('firstname');
    }
    available.forEach(item => {
        fields.push({ field: item, required: mandatory.includes(item) });
    });
    return fields;
};

class CreateAccount extends ContactTypemapComponent {
    static contextType = CustomThemeContext;
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            mounted: false,
            step: 0,
            showPassword: false,
            user: {},
            contact: {},
            wasFound: false,
            fields: [
                { field: 'firstname', required: true },
                { field: 'lastname', required: true },
            ],
            errors: {},
            verified: false,
            passwordtoken: null,
            ...this.props.location.state,
        };
    }

    componentDidMount() {
        //request token for used client
        const { code } = this.props.match.params;
        const { t } = this.props;
        AuthService
            .verificationCheck(code)
            .then(result => {
                AuthService
                    .registrationFields()
                    .then(result3 => {
                        const fields = createFields(result3.available ?? [], result3.mandatory ?? []);
                        if (fields.length > 0) {
                            this.setState({
                                fields: fields,
                            });
                        }
                        this.getContactTypemap();

                        this.setState({
                            mounted: true,
                        });
                    });

                AuthService
                    .getUniqueContactByEmail(result.username)
                    .then(result4 => {
                        const body = result4;
                        if (typeof body.contact !== 'undefined') {
                            this.setState({
                                wasFound: true,
                                contact: body.contact,
                            });
                        }
                    });

                const { user, verified, passwordtoken } = this.state;
                let newUser = { 
                    ...user,
                    username: result.username,
                };

                if (typeof result.user_id !== 'undefined') {
                    newUser['user_id'] = result.user_id;
                    if (verified && passwordtoken) {
                        newUser['password-token'] = passwordtoken;
                    }
                }

                this.setState({
                    user: newUser,
                    step: verified ? 1 : 0,
                });
            })
            .catch(err => {
                history.push('/');
                Events.dispatch('msgError', t('invalid-code'));
            });
    }
    

    handleClickNext = (e) => {
        const { t } = this.props;
        const { step } = this.state;
        const { username, password, user_id } = this.state.user;

        if (step === 0) {
            if (user_id) {
                AuthService
                    .check(username, password)
                    .then(response => {
                        this.setState({
                            step: 1
                        });
                    })
                    .catch(err => {
                        Events.dispatch('msgError', t('invalid-login'));
                    });
            } else {
                this.setState({
                    step: 1
                });
            }
        }
    }

    handleClickPrevious = (e) => {
        const { verified } = this.state;
        this.setState({
            // step: this.step - 1          //for generic steps
            step: verified ? 1 : 0,
        });
    };

    handleClickCreate = (e, loginmethod = null) => {
        //check for empty requried
        const { fields, user, contact } = this.state;
        const { t } = this.props;

        let errors = {};
        fields.forEach(item => {
            if (typeof item.required !== 'undefined' && item.required === true && (typeof contact[item.field] === 'undefined' || contact[item.field] === null || contact[item.field] === '')) {
                errors[item.field] = { 'validFormat':  t('field-is-required') };
            }
        });

        this.setState({
            errors: errors
        });

        if (Object.entries(errors).length === 0) {
            const { code } = this.props.match.params;

            Events.dispatch('startLoading');
            AuthService
                .register(code, user, contact)
                .then(response => {
                    if (response.status === 208) {
                        Events.dispatch('msgInfo', t('account-already-exists'));
                    } else {
                        Events.dispatch('msgSuccess', t('account-create-successfull'));
                        if (typeof response.data.token === 'string') {
                            const { token, configurations } = response.data;

                            localStorage.setItem("access-token", token);
                            
                            let isValidRedirect = null;
                            if (typeof configurations !== 'undefined' && typeof configurations.portal_registration_external_redirect === 'string') {
                                if (isValidHttpUrl(configurations.portal_registration_external_redirect)) {
                                    isValidRedirect = configurations.portal_registration_external_redirect;
                                }
                            }
                            
                            if (typeof loginmethod === 'function') {
                                loginmethod(response.data)
                                    .then(res => {
                                        if (isValidRedirect !== null) {
                                            window.location.replace(configurations.portal_registration_external_redirect);
                                        } else {
                                            history.push('/');
                                        }
                                    });
                            }
                        } else {
                            history.push('/');
                        }
                    }
                    Events.dispatch('doneLoading');
                })
                .catch(err => {
                    const res = err.response;
                    if (res) {
                        if (typeof res.data !== 'undefined' && typeof res.data.errors !== 'undefined') {
                            this.setState({
                                errors: res.data.errors ?? {},
                            });
                        }
                    }
                    Events.dispatch('doneLoading');
                    Events.dispatch('msgError', t('save-failed'));
                });
        } else {}
    } 

    handleUserChange = (e) => {
        const { user } = this.state;

        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            user: {...user, [name]: value}
        });

        if (name === 'language') {
            const { i18n } = this.props;
            i18n.changeLanguage(value);
        }
    }

    handleChangeBirthday = (date) => {
        this.handleContactChange({
            target: {
                name: 'birthday',
                value: date,
            }
        });
    }

    handleContactChange = (e) => {
        const { contact } = this.state;
        let { errors } = this.state;

        const name = e.target.name;
        const value = e.target.value;

        if (typeof errors[name] !== 'undefined') {
            delete errors[name];
        }

        this.setState({
            contact: {...contact, [name]: value},
            errors: errors,
        });
    }

    handleClickShowPassword(e) {
        this.setState({
            showPassword: !this.state.showPassword
        });
    }


    nextStepConditions = () => {
        const { step } = this.state;

        if (step === 1) {
            return true;
        }
        const { username, password, passwordRepeat, user_id } = this.state.user;
        if (!user_id) {
            if (!passwordRepeat) {
                return true;
            }
            if (password !== passwordRepeat) {
                return true;
            }
        }
        if (!username || !password) {
            return true;
        }
        if (!PasswordRequirements.isValidPassword(password)) {
            return true;
        }
        return false;
    }

    handleEnterOnCreate = (e) => {
        if (e.key === 'Enter') {
            const { step } = this.state;
            if (step === 0) {
                if (!this.nextStepConditions()) {
                    this.handleClickNext(e);
                }
            } else {
                this.handleClickCreate(e);
            }
        }
    }

    handleOnFocus = (e) => {
        const target = e.target;
        if (target) {
            setTimeout(() => {
                target.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }, 250);
        }
    }

    getData = () => {
        const { contact } = this.state;
        return contact;
    }

    handleOnChange = (value) => {
        this.handleContactChange(value);
    }

    render() {
        const { t, classes, i18n } = this.props;

        const { mounted, user, contact, step, fields, errors, showPassword, wasFound } = this.state;

        const inputProps = (item) => {
            const name = item.field ?? '';
            var props = {
                fullWidth: true,
                margin: 'normal',
                name:name,
                required: item.required ?? false,
                value: contact[name] ?? '',
                label: t(name),
                onChange: this.handleContactChange,
                onKeyPress: this.handleEnterOnCreate,
                onFocus: this.handleOnFocus,
                error: (typeof errors[name] !== 'undefined' ? true : undefined),
                className: (typeof errors[name] !== 'undefined' ? 'shake' : ''),
                helperText: undefined,
            };

            if (props.error) {
                if (typeof errors[name]['validFormat'] !== 'undefined') {
                    props['helperText'] = errors[name]['validFormat'];
                }
            }

            props['options'] = [{id: '', label: ''}];

            const { typemap, options } = this.state;
            if (typeof typemap[name] !== 'undefined') {
                if (['autocomplete', 'select'].includes(typemap[name]['type'])) {
                    if (typeof options[name] !== 'undefined') {
                        props['onChange'] = (e, v, r) => this.handleAutocompleteInputChange(e,v,r,name);
                        props['options'] = options[name];
                        props['value']      = null;
                        const selectedValue = this.getPatchedValue(name);
                        let selected = options[name].find(x => x.id == selectedValue);
                        if (selected && typeof selected !== 'undefined') {
                            props['value']   = selected;
                        }
                    }
                }
            }

            return props;
        };

        const inputPropsSubElement = (item) => {
            const props = inputProps(item);

            const { error, helperText, required, className, margin, ...rest } = props;
            return {
                error: error,
                helperText: helperText,
                required: required,
                className: className,
                margin: margin,
            };
        }

        const getRegistrationField = (item, index) => {
            if (['birthday'].includes(item.field)) {
                const inputPropsForDatepicker = inputProps(item);
                return <AdvancedDatepicker
                    inputProps={inputPropsForDatepicker}
                    onChange={this.handleChangeBirthday}
                    value={inputPropsForDatepicker.value ?? null}
                />;
            } else {
                if (['insurance_id', 'assignment_mode_id', 'family_doctor_id', 'delivery_preference_id'].includes(item.field)) {
                    return <Autocomplete
                        {...inputProps(item)}
                        ListboxComponent={AutoCompleteVirtualizedList}
                        renderOption={(props, option) => [props, option]}
                        renderInput={(params) => <TextField {...params} {...inputPropsSubElement(item)} label={t(this.getTitle(item.field))}/>}
                    />;
                } else {
                    return <TextField 
                        {...inputProps(item)}
                        autoFocus={index === 0}
                    />;
                }
            }
        }

        return (
            <Box className={classes.form}>
                {!mounted && <Box textAlign='center' marginTop={15}><CircularProgress size={150}/></Box>}
                {mounted && <React.Fragment>
                    {step === 0 && typeof user.user_id === 'undefined' && <Box>
                        <Typography component="h1" variant="h5"><ConfiguredCustomText fallback={t('create-account')} section={'createaccount'} label={'title'}/></Typography>
                        <Typography component="h5" variant="subtitle2" color="textSecondary"><ConfiguredCustomText fallback={t('needed-for-login')} section={'createaccount'} label={'primary'}/></Typography>
                        <Typography component="h5" variant="subtitle2" color="textSecondary"><ConfiguredCustomText section={'createaccount'} label={'secondary'}/></Typography>
                    </Box>}
                    {step === 0 && typeof user.user_id !== 'undefined' && <Box>
                        <Typography component="h1" variant="h5"><ConfiguredCustomText fallback={t('update-account')} section={'updateaccount'} label={'title'}/></Typography>
                        <Typography component="h5" variant="subtitle2" color="textSecondary"><ConfiguredCustomText fallback={t('use-existing-password')} section={'updateaccount'} label={'primary'}/></Typography>
                        <Typography component="h5" variant="subtitle2" color="textSecondary"><ConfiguredCustomText section={'updateaccount'} label={'secondary'}/></Typography>
                    </Box>}
                    {step === 1 && <Box>
                        <Typography component="h1" variant="h5"><ConfiguredCustomText fallback={t('basedata')} section={'createcontact'} label={'title'}/></Typography>
                        <Typography component="h5" variant="subtitle2" color="textSecondary"><ConfiguredCustomText fallback={t('needed-for-therapist')} section={'createcontact'} label={'primary'}/></Typography>
                        <Typography component="h5" variant="subtitle2" color="textSecondary"><ConfiguredCustomText section={'createcontact'} label={'secondary'}/></Typography>
                    </Box>}
                    <form
                        onSubmit={this.handleClickNext}
                        className={classes.form} 
                        noValidate
                    >
                        <Box style={{ flexGrow: 1, marginTop: 2, overflowY: 'auto' }}>
                            {step === 0 && <React.Fragment>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    required
                                    disabled
                                    id="username"
                                    name="username"
                                    label={t('email')}
                                    onChange={this.handleUserChange}
                                    onKeyPress={this.handleEnterOnCreate}
                                    onFocus={this.handleOnFocus}
                                    autoComplete="email"
                                    value={user.username ?? ''}
                                />
                                {typeof user.user_id !== 'undefined' && <React.Fragment>
                                    <TextField
                                        fullWidth
                                        id="password"
                                        name="password"
                                        label={t('password')}
                                        // autoComplete="current-password"
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={this.handleUserChange}
                                        onKeyPress={this.handleEnterOnCreate}
                                        onFocus={this.handleOnFocus}
                                        value={user.password ?? ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        // onMouseDown={handleMouseDownPassword}
                                                        onClick={() => this.handleClickShowPassword()}
                                                        size="large">
                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </React.Fragment>}
                                {typeof user.user_id === 'undefined' && <React.Fragment>
                                    <PasswordCreateField field="password" value={user.password ?? ''} autoFocus={true} onChange={this.handleUserChange} onKeyPress={this.handleEnterOnCreate} onFocus={this.handleOnFocus}/>
                                    <PasswordCreateField field="passwordRepeat" value={user.passwordRepeat ?? ''} onChange={this.handleUserChange} onKeyPress={this.handleEnterOnCreate} onFocus={this.handleOnFocus}/>
                                    {user.password && <React.Fragment>
                                        {user.password !== user.passwordRepeat && <Box style={{ marginTop: 2 }}>
                                            <CheckLabel value={false} label={t('passwords-dont-match')}/>
                                        </Box>}
                                        {!PasswordRequirements.hasLength(user.password) && <Box>
                                            <CheckLabel value={false} label={t('password-to-short')}/>
                                        </Box>}
                                    </React.Fragment>}
                                    <FormControl
                                        margin="normal" 
                                        fullWidth
                                    >
                                        <InputLabel id="language-label">{t('language')}</InputLabel>
                                        <Select 
                                            id="language" 
                                            labelId="language-label"
                                            label={t('language')}
                                            name="language" 
                                            value={user.language ?? (i18n.language).substring(0,2)}
                                            onChange={this.handleUserChange}
                                            onFocus={this.handleOnFocus}
                                        >
                                            <MenuItem value="de">{t('german')}</MenuItem>
                                            <MenuItem value="en">{t('english')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </React.Fragment>}
                            </React.Fragment>}
                            {step === 1 && <React.Fragment>
                                {wasFound && <Alert severity="info" sx={{ marginBottom: 2 }}>
                                    <Box>{t('you-were-found-in-the-database')}</Box>
                                    <Box>{t('please-check-and-complete-data')}</Box>
                                </Alert>}
                                <TextField
                                    fullWidth
                                    required
                                    disabled
                                    id="username"
                                    name="username"
                                    label={t('email')}
                                    autoComplete="email"
                                    value={user.username ?? ''}
                                    onFocus={this.handleOnFocus}
                                />
                                {fields.map((item, index) => {
                                    return getRegistrationField(item, index);
                                })}
                            </React.Fragment>}
                        </Box>
                        <CustomThemeContext.Consumer>
                            {({ isMobileKeyboardOpen }) => (
                                <AuthContext.Consumer>
                                     {({ setAuth }) => (
                                        <Box className={(isMobileKeyboardOpen && classes.spaceActions)}>
                                            <Grid container>
                                                <Grid item xs={1}></Grid>
                                                <Grid item xs={5}>
                                                    <Button fullWidth variant="contained" color="primary" disabled={step === 0} onClick={this.handleClickPrevious}>{t('back')}</Button>
                                                </Grid>
                                                <Grid item xs={5} style={{ marginLeft: '5px' }}>
                                                    {step === 0 && <Button fullWidth variant="contained" color="primary" disabled={this.nextStepConditions()} onClick={this.handleClickNext}>{t('next')}</Button>}
                                                    {step === 1 && <Button fullWidth variant="contained" color="primary" disabled={step === 0} onClick={(e) => this.handleClickCreate(e, setAuth)}>{t('create')}</Button>}
                                                </Grid>
                                            </Grid>
                                            <Grid container className={classes.spacer}>
                                                <Grid item xs></Grid>
                                                <Grid item>
                                                    <Link
                                                        to="/"
                                                        component={RouterLink}
                                                        variant="subtitle2"
                                                        color="secondary"
                                                        underline="hover">
                                                        {t('cancel')}
                                                    </Link>
                                                </Grid>
                                                <Grid item xs></Grid>
                                            </Grid>
                                        </Box>
                                     )}
                                </AuthContext.Consumer>
                            )}
                        </CustomThemeContext.Consumer>
                    </form>
                </React.Fragment>}
            </Box>
        );
    }
}

const TranslatedPasswordRequest = withTranslation('translation')(CreateAccount);
export default withStyles(useStyles)(TranslatedPasswordRequest);