import React, { Suspense, useEffect } from 'react';
import './App.css';
import './i18n.js';
import Authenticator from './Components/Auth/Authenticator.js';
import { Backdrop, CircularProgress } from '@mui/material';


import makeStyles from '@mui/styles/makeStyles';


function App() {
	return (
		<Authenticator/>
	);
}

const useStyles = makeStyles({
	backdrop: {
        zIndex: 999,
        color: '#f2a900',
    },
    spinner: {
        width: '200px !important',
        height: '200px !important'
    }
});

// here app catches the suspense from page in case translations are not yet loaded
export default function WrappedApp() {
	const classes = useStyles();

	return (
	  <Suspense fallback={
		<Backdrop open={true} className={classes.backdrop}>
			<CircularProgress color="inherit" className={classes.spinner}/>
		</Backdrop>}>
		<App />
	  </Suspense>
	);
  }