import { Backdrop, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import AuthService from '../../Services/AuthService.js';

const useStyles = (theme) => ({
    backdrop: {
        zIndex: 999,
    },
    spinner: {
        width: '200px !important',
        height: '200px !important'
    }
});

class CustomerSelectionByVerifyCodeBase extends Component {
    componentDidMount() {
        const { code } = this.props.match.params;
        const { setInitialLoad } = this.props;

        if (code !== undefined) {
            if (typeof setInitialLoad === 'function') {
                AuthService.clearAccessToken();
                AuthService.clearPublicToken();
                AuthService
                    .verificationCheck(code)
                    .then(result => {
                        localStorage.setItem("active-client", result.client_number);
                        setInitialLoad(true);
                    })
                    .catch(error => {
                        setInitialLoad(true);
                    });
            }
        } else {
            setInitialLoad(true);
        }
    }
    

    render() {
        const { classes } = this.props;
        return (
            <Backdrop open={true} className={classes.backdrop}>
                <CircularProgress color="primary" className={classes.spinner}/>
            </Backdrop>
        );
    }
}

export default withStyles(useStyles)(CustomerSelectionByVerifyCodeBase);