const ThemeHelper = {
    getCustomized(client_number = null) {
        var theme = {
            palette: {
                // mode: 'dark',
                primary: {
                    main: '#f2a900',
                },
                secondary: {
                    main: '#808B94',
                }
            },
            typography: {
                //TODO: style typography
            }
        };

        if (client_number) {
            var custom = localStorage.getItem('theme-' + client_number);
            if (custom) {
                custom = JSON.parse(custom);
                if (typeof custom.palette !== 'undefined') {
                    theme.palette = custom.palette;
                }
                if (typeof custom.mode !== 'undefined') {
                    theme.palette.mode = custom.mode;
                }
                if (typeof custom.primary !== 'undefined') {
                    theme.palette.primary.main = custom.primary;
                }
                if (typeof custom.secondary !== 'undefined') {
                    theme.palette.secondary.main = custom.secondary;
                }
                if (typeof custom.typography !== 'undefined') {
                    theme.typography = custom.typography;
                }
            }
        }
        return theme;
    },
    getClientLogoByTheme(client_number = null) {
        if (client_number) {
            var custom = localStorage.getItem('theme-'+client_number);
            if (custom) {
                custom = JSON.parse(custom);

                if (typeof custom.logo_url !== 'undefined') {
                    return custom.logo_url;
                }
            }
        }
        return null;
    },
    getClientHeaderBackgroundColor(client_number = null) {
        if (client_number) {
            var custom = localStorage.getItem('theme-'+client_number);
            if (custom) {
                custom = JSON.parse(custom);

                if (typeof custom.header_background_color !== 'undefined') {
                    return custom.header_background_color;
                }
            }
        }
        return null;
    },
    getClientBackground(client_number = null) {
        if (client_number) {
            var custom = localStorage.getItem('theme-'+client_number);
            if (custom) {
                custom = JSON.parse(custom);

                if (typeof custom.background_url !== 'undefined') {
                    return custom.background_url;
                }
            }
        }
        return null;
    },
    getClientBackgroundScoped(client_number = null) {
        if (client_number) {
            var custom = localStorage.getItem('theme-'+client_number);
            if (custom) {
                custom = JSON.parse(custom);

                if (typeof custom.background_url_scoped !== 'undefined') {
                    return custom.background_url_scoped;
                }
            }
        }
        return [];
    },
    getBreakpoint (breakpoints = {}) {
        let bp = null;
        (Object.entries(breakpoints)).forEach(([key, value]) => {
            if (window.innerWidth >= value) {
                bp = key;
            }
        });
        return bp;
    }
};

export default ThemeHelper;