import { Backdrop, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import AuthService from '../../Services/AuthService.js';

const useStyles = (theme) => ({
    backdrop: {
        zIndex: 999,
    },
    spinner: {
        width: '200px !important',
        height: '200px !important'
    }
});

class CustomerSelectionBase extends Component {
    componentDidMount() {
        const { clientNumber } = this.props.match.params;
        const { setInitialLoad } = this.props;

        if (clientNumber !== undefined) {
            if (typeof setInitialLoad === 'function') {
                const acl = parseInt(AuthService.getActiveClientNumber());
                if (parseInt(clientNumber) !== acl) {
                    AuthService.clearAccessToken();
                    AuthService.clearPublicToken();
                    localStorage.setItem("active-client", clientNumber);
                    setInitialLoad(true);
                } else {
                    setInitialLoad(true);
                }
            }
        } else {
            setInitialLoad(true);
        }
    }
    

    render() {
        const { classes } = this.props;
        return (
            <Backdrop open={true} className={classes.backdrop}>
                <CircularProgress color="primary" className={classes.spinner}/>
            </Backdrop>
        );
    }
}

export default withStyles(useStyles)(CustomerSelectionBase);