import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthService from '../../Services/AuthService';
import Events from '../../Utility/Events';
import history from '../../Utility/RouterHistory';
import { CustomThemeContext } from './CustomThemeContext';
import { is } from 'date-fns/locale';

const DEFAULT_TABS = [
    'basedata',
    'bookmarks',
    'questionaires',
    'appointments',
    'documents',
    'programs',
    'receipts',
];

export const AuthContext = React.createContext({
    initial: true,
    user: null,
    client: null,
    clientConfigurations: null,
    currentPublicClientConfigurations: null,
    associations: [],
    changeClient: null,
    switchTheme: null,
    contact: null,
    setContact: null,
    login: null,
    refresh: null,
    logout: null,
    privacyConsent: false,
    handlePrivacyConsent: null,
    privacyConsentDate: null,
    hideSections: [],
    tabs: DEFAULT_TABS,
    isAuthenticated: false,
    hasSophyappAccess: null,
});

const AuthContextProvider = (props) => {
    const { children } = props;
    
    const { changeTheme, currentClient, currentPublicClientConfigurations } = useContext(CustomThemeContext);

    const { t, i18n } = useTranslation();

    const [ initial, setInitial ] = useState(true); //prevents the layout to be loaded before checking if logged in
    const [ initialLoad, setInitialLoad ] = useState(false); //prevents that the check is triggered multiple times
    const [ user, setUser ] = useState(null);
    const [ client, setClient ] = useState(null);
    const [ clientConfigurations, setClientConfigurations ] = useState(null); 
    const [ contact, setContact ] = useState(null);
    const [ associations, setAssociations ] = useState([]); 
    const [ privacyConsent, setPrivacyConsent ] = useState(false);
    const [ privacyConsentDate, setPrivacyConsentDate] = useState(null);
    const [ hideSections, setHideSections ] = useState([]);
    const [ tabs, setTabs ] = useState(DEFAULT_TABS);

    const [showInitialDSGVOTimoutId, setShowInitialDSGVOTimoutId] = useState(null);

    useEffect(() => {
        if (initialLoad === true) {
            setInitialLoad(false);
            if (AuthService.getAccessToken()) {
                refresh();
            } else {
                setInitial(false);
            }
        };
    });

    useEffect(() => {
        if (client && client.initial) {
            setPrivacyConsent(true);
            setPrivacyConsentDate(client.initial);

            if (showInitialDSGVOTimoutId) {
                clearTimeout(showInitialDSGVOTimoutId);
            }
            Events.dispatch('hideDSGVO');
        } else {
            setPrivacyConsent(false);
            setPrivacyConsentDate(null);

            setShowInitialDSGVOTimoutId(setTimeout(() => {
                Events.dispatch('showDSGVO');
            }, 500));
        }
    }, [client]);

    useEffect(() => {
        if (client && typeof client.contact_id !== 'undefined') {
            localStorage.setItem("active-contact-id", client.contact_id)
        } else {
            localStorage.setItem("active-contact-id", null);
        }
    }, [client]);

    useEffect(() => {
        if (clientConfigurations && typeof clientConfigurations.portal_hide_sections !== 'undefined') {
            setHideSections(clientConfigurations.portal_hide_sections);
        } else {
            setHideSections([]);
        }
    }, [clientConfigurations]);
    
    useEffect(() => {
        let patchedHideSection = Object.assign([], hideSections);
        if (patchedHideSection.includes('anamnesis')) {
            patchedHideSection.push('questionaires');
        }
        if (patchedHideSection.includes('contacts')) {
            patchedHideSection.push('basedata');
        }
        if (patchedHideSection.includes('dates')) {
            patchedHideSection.push('appointments');
        }
        if (!hasSophyappAccess()) {
            patchedHideSection.push('programs');
        }
        setTabs(DEFAULT_TABS.filter(i => !patchedHideSection.includes(i)));
    }, [hideSections, contact]);

    const hasSophyappAccess = () => {
        if (clientConfigurations) {
            if (typeof clientConfigurations.sop_account_id !== 'undefined' && clientConfigurations.sop_account_id) {
                if (contact) {
                    if (typeof contact.sop_patient_id !== 'undefined' && contact.sop_patient_id) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    const setAuth = (auth = {}) => {
        return new Promise((resolve, reject) => {
            let isValid = false;
            if (typeof auth.user !== 'undefined') {
                if (typeof auth.user.language !== 'undefined') {
                    i18n.changeLanguage(auth.user.language);
                }
                isValid = true;
                setUser(auth.user);
                if (typeof auth.clients !== 'undefined') {
                    setAssociations(auth.clients);

                    if (typeof auth.client !== 'undefined' && auth.client) {
                        setClient(auth.client);
                        setClientConfigurations(auth.configurations);
                        setContact(auth.contact);

                        Events.dispatch('doneLoading');
        
                        return resolve();
                    } else {
                        const mainClientNumbers = (auth.clients).map(i => i.main_client_number);
                        const activeClientNumber = localStorage.getItem('active-client');
                        const activeMainClientNumber = localStorage.getItem('main-client');
                        if (activeMainClientNumber) {
                            //create a verification code if not associated to client
                            if (mainClientNumbers.indexOf(parseInt(activeMainClientNumber)) === -1) {
                                const hasPendingBooking = sessionStorage.getItem('booking-' + activeMainClientNumber);
                                if (hasPendingBooking) {
                                    return AuthService
                                        .inlineVerificationRequest({
                                            client_number: activeClientNumber,
                                        })
                                        .then(res => {
                                            const data = res.data;

                                            history.push({
                                                pathname: '/create/' + data.verification,
                                                state: {
                                                    verified: true,
                                                    passwordtoken: data.passwordtoken,
                                                }
                                            });

                                            return resolve();
                                        })
                                        .catch(err => {
                                            Events.dispatch('msgError', t('verification-failed'));
                                            logout();

                                            return reject();
                                        });
                                }
                            } else {
                                const matching = (auth.clients).filter(client => parseInt(client.main_client_number) === parseInt(activeMainClientNumber));
                                if (matching.length === 1) {
                                    const matchingClient = matching[0];
                                    let contactId = matchingClient.contact_id ?? null;
                                    if (typeof matchingClient.contact !== 'undefined') {
                                        contactId = matchingClient.contact.id ?? contactId;
                                    } 
                                    return AuthService
                                        .refresh(matchingClient.user_client_id, contactId)
                                        .then(response => {
                                            setClientConfigurations(response.configurations);
                                            setClient(response.client);
                                            setContact(response.contact);
                                            
                                            Events.dispatch('doneLoading');

                                            return resolve(response);
                                        })
                                        .catch(err => {
                                            logout();

                                            return reject();
                                        });
                                }
                            }
                        }
                    }
                }
            } else {
                setUser(null);
                setClient(null);
                setClientConfigurations(null);
                setContact(null);

                return resolve();
            }

            if (isValid) {
                history.push('/client');
                return resolve();
            }

            clearAuth();
            return reject();
        });
    }

    const setAuthOld = (auth = {}) => {
        return new Promise((resolve, reject) => {
            if (typeof auth.user !== 'undefined') {
                if (typeof auth.user.language !== 'undefined') {
                    i18n.changeLanguage(auth.user.language);
                }
            }
            return AuthService
                .clients()
                .then(response => {
                    const activeClients = response.data ?? [];
                    const activeClientNumbers = (activeClients).map(i => i.client_number);
                    const activeMainClientNumbers = (activeClients).map(i => i.main_client_number);
                    setAssociations(activeClients);
                    //check for pending booking
                    let proceed = true; 
                    if (localStorage.getItem('active-client')) {
                        const acl = localStorage.getItem('active-client');
                        if (sessionStorage.getItem('booking-'+parseInt(currentPublicClientConfigurations.main_client_number))) {
                            //create a verification code if not associated to client
                            if (activeClientNumbers.indexOf(parseInt(acl)) === -1) {
                                if (currentPublicClientConfigurations.main_client_number !== parseInt(acl)) {
                                    proceed = false;
                                    if (currentPublicClientConfigurations.registration ?? false) {
                                        AuthService
                                            .inlineVerificationRequest({
                                                client_number: acl,
                                            })
                                            .then(res => {
                                                const data = res.data;
                                                resolve();
                                                history.push('/create/' + data.verification);
                                            })
                                            .catch(err => {
                                                Events.dispatch('msgError', t('verification-failed'));
                                                resolve();
                                                logout();
                                            });
                                    } else {
                                        Events.dispatch('msgError', t('registration-disabled'));
                                        resolve();
                                        logout();
                                    }
                                }
                            }
                        }
                    }

                    if (proceed) {
                        let directLogin = false;
                        //check active 
                        var acl = localStorage.getItem('active-client');
                        if (acl) {
                            acl = parseInt(acl);
                            if (typeof auth.client !== 'undefined' && auth.client && (auth.client.client_number === acl || currentPublicClientConfigurations.main_client_number === acl)) {
                                directLogin = true;
                            } else {
                                if (activeMainClientNumbers.indexOf(acl) !== -1) {
                                    const key1 = activeMainClientNumbers.indexOf(acl);
                                    const key2 = activeMainClientNumbers.lastIndexOf(acl);
                                    if (key1 === key2) {
                                        auth.client = activeClients[key1];
                                        directLogin = true;
                                    }
                                }
                            }
                        } else {
                            if (activeMainClientNumbers.length == 1) {
                                auth.client = activeClients[0];
                                directLogin = true;
                            } 
                        }
                        if (directLogin) {
                            changeClient(auth.client)
                                .then(res2 => {
                                    setUser(auth.user ?? null);
                                    resolve();
                                });
                        } else {
                            setUser(auth.user ?? null);
                            setClient(null);
                            setClientConfigurations(null);
                            setContact(null);
                            resolve();
                            history.push('/client');
                        }
                    }
                })
                .catch(err => {
                    clearAuth();
                    reject(err);
                }); 
        });
    }

    const login = (data) => {
        return AuthService
            .login(data.username, data.password)
            .then(response => {
                return setAuth(response).then(res2 => response);
            })
            .catch(err => {
                if (err.response.status === 401) {
                    Events.dispatch('msgError', t('invalid-client'));
                } else {
                    Events.dispatch('msgError', t('invalid-login'));
                }
                throw err;
            });
    }

    const logout = () => {
        Events.dispatch("startLoading");
        return new Promise((resolve, reject) =>{
            return setAuth({})
                .then(res => {
                    AuthService.logout();
                    Events.dispatch("doneLoading");
                    resolve();
                });
        });
    }

    const clearAuth = () => {
        setUser(null);
        setClient(null);
        setClientConfigurations(null);
        setContact(null);
    }
    
    const checkOrLogout = (clientNumber) => {
        return new Promise(resolve => {
            if (currentClient && clientNumber !== currentClient) {
                AuthService.logout();
                clearAuth();
                setTimeout(() => {
                    resolve();
                }, 500);
            } else {
                resolve();
            }
        });
    }

    const switchTheme = (clientNumber) => {
        return checkOrLogout(clientNumber)
            .then(r => {
                return changeTheme(clientNumber);
            });
    }

    const changeClient = (client) => {
        if (client) {
            return changeTheme(client.client_number)
                .then(res => {
                    let contactId = client.contact_id ?? null;
                    if (typeof client.contact !== 'undefined') {
                        contactId = client.contact.id ?? contactId;
                    } 
                    return AuthService
                        .refresh(client.user_client_id, contactId)
                        .then(response => {
                            setClientConfigurations(response.configurations);
                            setClient(response.client);
                            setContact(response.contact);
                            return response;
                        });
                });

        } else {
            return new Promise((resolve, reject) =>{
                setClient(null);
                setClientConfigurations(null);
                setContact(null);
                resolve();
                history.push('/client');
            });
        }
    }

    const handlePrivacyConsent = () => {
        return AuthService
                .setPrivacyConsent()
                .then(response => {
                    setPrivacyConsent(true);
                    setPrivacyConsentDate(new Date());

                    Events.dispatch('hideDSGVO');

                    return response;
                });
    }

    const isAuthenticated = () => {
        if (client && user && clientConfigurations) {
            return true;
        }
        return false;
    }

    const refresh = () => {
        return AuthService
            .refresh()
            .then(response => {
                return setAuth(response).then(res2 => {
                    setInitial(false);
                    return response;
                });
            })
            .catch(err => {
                Events.dispatch('msgInfo', t('please-log-in'));
                AuthService.clearAccessToken();
                setInitial(false);
            });
    }

    const contextValues = {
        initial: initial,
        user: user,
        client: client,
        clientConfigurations: clientConfigurations,
        currentPublicClientConfigurations: currentPublicClientConfigurations,
        associations: associations,
        changeClient: changeClient,
        switchTheme: switchTheme,
        contact: contact,
        setContact: setContact,
        login: login,
        refresh: refresh,
        setAuth: setAuth,
        logout: logout,
        privacyConsent: privacyConsent,
        handlePrivacyConsent: handlePrivacyConsent,
        privacyConsentDate: privacyConsentDate,
        hideSections: hideSections,
        tabs: tabs,
        isAuthenticated: isAuthenticated,
        hasSophyappAccess: hasSophyappAccess,
        setInitialLoad: setInitialLoad,
    };

    return <AuthContext.Provider value={contextValues}>
        {children}
    </AuthContext.Provider>
}

export default AuthContextProvider;