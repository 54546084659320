import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthService from '../../Services/AuthService';
import Events from '../../Utility/Events';
import history from '../../Utility/RouterHistory';
import ThemeHelper from '../../Utility/ThemeHelper';
import WindowHelper from '../../Utility/WindowHelper';

const defaultDeviceHeight = window.innerHeight;

const CONTEXT_DEFAULTS = {
    theme: false,
    changeTheme: null,
    logo: null,
    background: null,
    backgroundScoped: [],
    currentClient: null,
    currentPublicClientConfigurations:{},
    isMobileKeyboardOpen: false,
    hasSidebarMenu: false,
};

export const CustomThemeContext = React.createContext(CONTEXT_DEFAULTS);

const CustomThemeProvider = (props) => {
    const { children } = props;

    const currentClientNumber = AuthService.getActiveClientNumber();

    const { t } = useTranslation();
    
    const [ initial, setInitial ]       = useState(false);
    const [ theme, setTheme ]           = useState(createTheme(ThemeHelper.getCustomized(currentClientNumber)));
    const [ logo, setLogo ]             = useState(ThemeHelper.getClientLogoByTheme(currentClientNumber));
    const [ background, setBackground ] = useState(ThemeHelper.getClientBackground(currentClientNumber));
    const [ backgroundScoped, setBackgroundScoped ] = useState(ThemeHelper.getClientBackgroundScoped(currentClientNumber));
    const [ currentPublicClientConfigurations, setCurrentPublicClientConfigurations ] = useState(CONTEXT_DEFAULTS.currentPublicClientConfigurations);
    const [ isMobileKeyboardOpen, setIsMobileKeyboardOpen ] = useState(CONTEXT_DEFAULTS.isMobileKeyboardOpen);

    useEffect(() => {
        if (initial === true) {
            setInitial(false);

            if (currentClientNumber) {
                changeTheme(currentClientNumber);
            }
            
            if (WindowHelper.isMobileDevice()) {
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
                window.onresize = () => {
                    if (window.innerHeight < defaultDeviceHeight) {
                        setIsMobileKeyboardOpen(true);
                    } else {
                        setIsMobileKeyboardOpen(false);
                    }
                }
            }
        }
    });


    const changeClient = (clientNr) => {
        setTheme(createTheme(ThemeHelper.getCustomized(clientNr)));
        setLogo(ThemeHelper.getClientLogoByTheme(clientNr));
        setBackground(ThemeHelper.getClientBackground(clientNr));
        setBackgroundScoped(ThemeHelper.getClientBackgroundScoped(clientNr));
    }

    const changeTheme = (clientNr) => {
        return AuthService
            .publicToken(clientNr)
            .then(res => {
                setCurrentPublicClientConfigurations(res.configurations ?? {});
                changeClient(clientNr);
                setTimeout(() => {
                    return res;
                }, 1500);
            })
            .catch(err => {
                Events.dispatch('msgError', t('invalid-client'));
                history.push('/');
            });
    }

    const hasSidebarMenu = () => {
        if (currentPublicClientConfigurations) {
            if (typeof currentPublicClientConfigurations.sidebar_menu !== 'undefined') {
                if (currentPublicClientConfigurations.sidebar_menu) {
                    return true;
                }
            }
        }
        return false;
    }

    const contextValues = {
        theme: theme,
        changeTheme: changeTheme,
        logo: logo,
        background: background,
        backgroundScoped: backgroundScoped,
        currentClient: currentClientNumber,
        currentPublicClientConfigurations: currentPublicClientConfigurations,
        isMobileKeyboardOpen: isMobileKeyboardOpen,
        hasSidebarMenu: hasSidebarMenu,
        setInitialLoad: setInitial, 
    };

    return <CustomThemeContext.Provider value={contextValues}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </StyledEngineProvider>
    </CustomThemeContext.Provider>
}

export default CustomThemeProvider;