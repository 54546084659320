import { Card, CardContent, Container, Fade, Link, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Box } from '@mui/system';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { CustomThemeContext } from '../Contexts/CustomThemeContext';


const useStyles = (theme) => ({
    root: {
        minHeight: 'calc(var(--vh, 1vh) * 100)',
        maxHeight: 'calc(var(--vh, 1vh) * 100)', 
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down('md')]: {
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100vw',
                paddingLeft: 0,
                paddingRight: 0,
            }
        }
    },
    paper: {
        height: "750px",
        maxHeight: 'calc(var(--vh, 1vh) * 80)',
        width: '100%',
        padding: 0,
        boxSizing:'border-box',
        alignItems: "center",
        [theme.breakpoints.down('md')]: {
            maxHeight: 'calc(var(--vh, 1vh) * 95)',
        },
        [theme.breakpoints.down('sm')]: {
            height: 'calc(var(--vh, 1vh) * 100)',
            maxHeight: 'calc(var(--vh, 1vh) * 100)'
        }
    },
    showKeyboardPaper: {
        [theme.breakpoints.down('sm')]: {
            height: 'unset !important',
            maxHeight: '100vh'
        }
    },
    card: {
        overflow: 'visible',
        backgroundColor: (theme.palette.mode === 'dark' ? '#000000d9' : '#ffffffd9'),
        [theme.breakpoints.down('md')]: {
            padding: 0,
            boxShadow: 'unset'
        }
    },
    showKeyboard: {
        position: 'absolute',
        top: 0,
        overflow: 'auto',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
            maxHeight: '100vh'
        }
    },
    cardContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            padding: 0,
        }
    },
    logoContainer: {
        position: 'relative',
        height: theme.spacing(20),
        minHeight: theme.spacing(20),
        maxHeight: theme.spacing(20),
    },
    logo: {
        position: 'absolute',
        maxHeight: '100%',
        maxWidth: '100%',
        boxSizing: 'border-box',
        padding: theme.spacing(4)
    },
    image: {
        textAlign: 'center',
        marginTop: theme.spacing(2)
    },
    content: {
        flexGrow: 1,
        maxHeight: '100%',
        overflow: 'hidden',
    },
    backgroundImage: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: '-1',
    },
    dsgvoLink: {
        textAlign: 'center',
        marginTop: '-' + theme.spacing(3),
        marginBottom: theme.spacing(1),
        opacity: 0.6,
        transition: 'opacity 0.1s ease-in-out',
        '&:hover': {
            opacity: 1
        }
    },
    showKeyboardDsgvo: {
        display: 'none',
    }
});

class Layout extends Component {
    render() {
        const { t, classes, children } = this.props;

        const getScopedBackgroundImage = () => {
            return <CustomThemeContext.Consumer>
                {({background, backgroundScoped}) => (
                    <React.Fragment>
                        {background && <Box className={classes.backgroundImage} sx={{ backgroundImage: "url('"+background+"')" }}></Box>}
                    </React.Fragment>
                )}
            </CustomThemeContext.Consumer>;
        }

        const getClientLogo = (logo) => {
            return <Box className={classes.logoContainer}>
                <Fade in={typeof logo !== 'string' || logo === ''}>
                    <img src={process.env.PUBLIC_URL + '/images/treatsoft.svg'} className={classes.logo} alt="treatsoft" />
                </Fade>
                <Fade in={typeof logo === 'string' && logo !== ''}>
                    <img src={logo} className={classes.logo} alt="treatsoft" />
                </Fade>
            </Box>;
        }

        return (
            <Box>
                {getScopedBackgroundImage()}
                <Container maxWidth="xs" className={classes.root}>
                    <CustomThemeContext.Consumer>
                        {({ currentClient, logo, isMobileKeyboardOpen }) => (
                            <Card className={classes.card + ' ' + (isMobileKeyboardOpen && classes.showKeyboard)}>
                                <Container className={classes.paper + ' ' + (isMobileKeyboardOpen && classes.showKeyboardPaper)}>
                                    <CardContent className={classes.cardContent}>
                                        {getClientLogo(logo)}
                                        <Container className={classes.content}>
                                            {children}
                                        </Container>
                                    </CardContent>
                                </Container>
                                <React.Fragment>
                                    {currentClient && <Box className={classes.dsgvoLink + ' ' + (isMobileKeyboardOpen && classes.showKeyboardDsgvo)}>
                                        <Link to={'/dsgvo/' + currentClient} variant="caption" color="secondary" underline="hover" component={RouterLink}>{t('DSGVO')}</Link>
                                    </Box>}
                                </React.Fragment>
                            </Card>
                        )}
                    </CustomThemeContext.Consumer>
                </Container>
            </Box>
        );
    }
}
const  TranslatedComponent = withTranslation('translation')(Layout);
export default withStyles(useStyles)(TranslatedComponent);