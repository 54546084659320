import { Close, ContactPage as ContactPageIcon, ContentCopy, EventNote as EventNoteIcon, FileCopy as FileCopyIcon, FitnessCenter as FitnessCenterIcon, Login as LoginIcon, Menu, Quiz as QuizIcon, Receipt as ReceiptIcon } from '@mui/icons-material';
import {
    AppBar, BottomNavigation,
    BottomNavigationAction,
    Box, Button, Link as ButtonLink, Card, Container, Divider, Drawer, Fade,
    Grid,
    Hidden,
    IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab,
    Tabs, Toolbar, Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, Router } from 'react-router-dom';
import Events from '../../Utility/Events.js';
import history from '../../Utility/RouterHistory';
import WindowHelper from '../../Utility/WindowHelper.js';
import { AuthContext } from '../Contexts/AuthContext.js';
import { CustomThemeContext } from '../Contexts/CustomThemeContext.js';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText.js';
import DsgvoDialog from '../Elements/DSGVO/DsgvoDialog.js';
import ProfileMenu from '../Elements/ProfileMenu.js';
import ThemeHelper from '../../Utility/ThemeHelper.js';


const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
        height: '100vh',
        overflowY: 'scroll',
        zIndex: 1
    },
    rootContainer: {
        position: 'relative',
        height: '100%',
        minHeight: '100%',
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 0,
            paddingRight: 0,
        }
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'unset',
        minHeight: '100%',
        backgroundColor: (theme.palette.mode === 'dark' ? '#000000d9' : '#ffffffd9'),
        [theme.breakpoints.down('md')]: {
            paddingBottom: '1px', //strange bug for bottom space on mobile, otherwise container gets cropped
        }
    },
    menubar: {
        display: "flex",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    menuAvatarBox: {
        textDecoration: 'none',
        color: 'inherit',
    },
    menuAvatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    menuAvatarCaption: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        width: '250px'
    },
    logoContainer: {
        position: 'relative',
        height: theme.spacing(19),
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(15),
        }
    },
    logo: {
        position: 'absolute',
        maxHeight: '100%',
        height: '100%',
        maxWidth: '100%',
        objectFit: 'contain',
        boxSizing: 'border-box',
        padding: theme.spacing(4),
    },
    mainappbar: {
        zIndex: 998,
        [theme.breakpoints.down('md')]: {
            position: 'sticky',
            top:0,
        }
    },
    sidebarMenu: {
        minWidth: theme.spacing(30),
    },
    sidebarMenuDivider: {
        position: 'absolute', 
        top: 0, 
        height: '99%',
        left: theme.spacing(30),
    },
    title: {
        flexGrow: 1,
    },
    content: {
        position: 'relative',
        minHeight: '100%',
        [theme.breakpoints.down('md')]: {
            minHeight: 'calc(100% + ' + theme.spacing(22) + ')',
            paddingBottom: theme.spacing(24),
        }
    },
    contentIOS: {
        [theme.breakpoints.down('md')]: {
            minHeight: '100%',
        }
    },
    profile: {
        textAlign: 'right',
        marginRight: theme.spacing(1),
        '& svg': {
            marginLeft: '5px',
        }
    },
    profilebtn: {
        borderRadius: '5px',
    },
    profilemenu: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        '& svg': {
            marginRight: '10px',
        },
        '& ul': {
            paddingTop: 0,
            paddingBottom: 0,
        }
    },
    bottomNavItem: {
        minWidth: '50px',
    },
    bottomNavContainer: {},
    backgroundImage: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: -1,
        backgroundSize: 'cover',
    },
    minimumDivider: {
        marginTop: '0 !important',
        marginBottom: '0 !important'
    },
    bottomInfos: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        textAlign: 'center',
        zIndex: 1,
        [theme.breakpoints.down('md')]: {
            bottom: theme.spacing(22)
        }
    },
    bottomInfosWithBar: {
        [theme.breakpoints.down('md')]: {
            bottom: theme.spacing(30)
        }
    },
    bottomInfosWithoutBar: {
        [theme.breakpoints.down('md')]: {
            bottom: 0
        }
    },
    providerText: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    topTabMenu: {
        opacity: 1,
        '&.Mui-selected': {
            fontWeight: 'bold',
        }
    }
});

const iconMap = {
    basedata: <ContactPageIcon />,
    questionaires: <QuizIcon />,
    appointments: <EventNoteIcon />,
    documents: <FileCopyIcon />,
    receipts: <ReceiptIcon />,
    bookmarks: <ContentCopy />,
    programs: <FitnessCenterIcon />
}

class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: false,
            showDSGVO: false,
            showMenu: false,
        };

        this.scrollContainerRef = React.createRef();
        this.sidebarMenuRef = React.createRef();
        this.lastScrollTop = null;
    }

    

    handleShowDSGVO = (e) => {
        this.setState({
            showDSGVO: true
        });
    }

    handleHideDSGVO = (e) => {
        this.setState({
            showDSGVO: false
        });
    }

    handleShowMenu = (e) => {
        this.setState({
            showMenu: true,
        });
    }

    handleHideMenu = (e) => {
        this.setState({
            showMenu: false,
        });
    }

    handleTabChange = (e, newValue) => {
        this.setState({tab: newValue});
    }

    scrollListener = (e) => {
        let bottom = e.target.scrollHeight - e.target.clientHeight

        if (this.lastScrollTop === null) {
            this.lastScrollTop = e.target.scrollTop;
        } else {
            if (this.lastScrollTop < e.target.scrollTop) {
                //scroll down
                Events.dispatch('scrollDown', e);
            } else {
                //scroll up
                Events.dispatch('scrollUp', e);
            }
            this.lastScrollTop = e.target.scrollTop;
        }

        if (e.target.scrollTop > bottom - 200) {
            // Events.dispatch('scrolledToBottom', e);
        }
        if (this.sidebarMenuRef && this.sidebarMenuRef.current) {
            this.sidebarMenuRef.current.style.marginTop = e.target.scrollTop + 'px';
        }
    }

    componentDidMount() {
        const { client, isAuthenticated, currentPublicClientConfigurations } = this.context;

        if (isAuthenticated() && client) {
            /**
             * check if there is a booking pending
             */
            let pendingBooking = sessionStorage.getItem('booking-' + currentPublicClientConfigurations.main_client_number);
            if (pendingBooking) {
                history.push('/appointments/booking');
            }
        }

        if (this.scrollContainerRef && this.scrollContainerRef.current) {
            this.scrollContainerRef.current.addEventListener('scroll', this.scrollListener);
        }

        this.updateTabMenu();

        Events.on('showDSGVO', (e) => {
            this.handleShowDSGVO(e);
        });
        Events.on('hideDSGVO', (e) => {
            this.handleHideDSGVO(e);
        });
    }
    
    componentWillUnmount() {
        if (this.scrollContainerRef && this.scrollContainerRef.current) {
            this.scrollContainerRef.current.removeEventListener('scroll', this.scrollListener);
        }
        Events.remove('showDSGVO');
        Events.remove('hideDSGVO');
    }

    updateTabMenu = () => {
        if (typeof this.context.tabs !== 'undefined') {
            const { tabs } = this.context;
        
            const { pathname } = history.location;
            if (pathname) {
                const { tab } = this.state;
                let newTab      = tab;
                let pathnameStr = pathname.split('/');
                pathnameStr = pathnameStr[1] ?? null;

                let searchKey   = pathnameStr;
                if (pathnameStr === null || pathnameStr === '') {
                    searchKey = tabs[0] ?? 'unknown';
                    const { clientConfigurations } = this.context;
                    if (typeof clientConfigurations.portal_start_page === 'string') {
                        searchKey = clientConfigurations.portal_start_page;
                    }
                }
                if (tabs.includes(searchKey)) {
                    newTab = tabs.indexOf(searchKey);
                } else {
                    newTab = false;
                }
                if (newTab !== tab) {
                    this.setState({
                        tab: newTab
                    });
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.updateTabMenu();
    }
    
    

    render() {
        const { t, classes, children }   = this.props;
        const { tab, showDSGVO, showMenu } = this.state;

        const getScopedBackgroundImage = () => {
            return <CustomThemeContext.Consumer>
                {({ background, backgroundScoped }) => (
                    <React.Fragment>
                        {background && <Box className={classes.backgroundImage} sx={{ backgroundImage: "url('"+background+"')" }}></Box>}
                        {backgroundScoped.map((url, index) => {
                            return <Fade key={'dynamic-background-'+index} in={tab === index}>
                                <Box className={classes.backgroundImage} sx={{ backgroundImage: "url('"+url+"')" }}></Box>
                            </Fade>;
                        })}
                    </React.Fragment>
                )}
            </CustomThemeContext.Consumer>;
        }

        const getClientLogo = (logo, clientNumber) => {
            if (typeof logo === 'string' && logo !== '') {
                return <Box className={classes.logoContainer} sx={{ backgroundColor: ThemeHelper.getClientHeaderBackgroundColor(clientNumber) ?? undefined }}>
                    <Fade in={typeof logo === 'string' && logo !== ''}>
                        <img src={logo} className={classes.logo} alt="treatsoft" />
                    </Fade>
                </Box>;
            }
            return <Box></Box>;
        }
        
        const getNavigationIcon = (tabItem) => {
            return iconMap[tabItem] ?? null;
        }
        const getBottomNavigationItem = (tabItem) =>  {
            return <BottomNavigationAction key={'bni-'+tabItem} component={Link} className={classes.bottomNavItem} to={"/"+tabItem} label={<ConfiguredCustomText fallback={t(tabItem)} section={tabItem} label={'title'}/>} icon={iconMap[tabItem]} />;
        }

        const getMenuList = (tabs, tab) => {
            return tabs.map((tabItem, index) => {
                return <ListItem key={'sidebar-menu-item'+ index}>
                    <ListItemButton selected={tab === index} component={Link} to={"/"+tabItem} onClick={this.handleHideMenu}>
                        <ListItemIcon>
                            {getNavigationIcon(tabItem)}
                        </ListItemIcon>
                        <ListItemText primary={<ConfiguredCustomText fallback={t(tabItem)} section={tabItem} label={'title'}/>} />
                    </ListItemButton>
                </ListItem>;
            });
        }

        return (
            <Router history={history}>
                <AuthContext.Consumer>
                    {({ client, tabs, isAuthenticated, privacyConsent }) => (
                        <CustomThemeContext.Consumer>
                            {({ isMobileKeyboardOpen, hasSidebarMenu }) => (
                                <Box>
                                    {isAuthenticated() && <DsgvoDialog show={showDSGVO} onClose={this.handleHideDSGVO}/>}
                                    {getScopedBackgroundImage()}
                                    <Box className={classes.root} ref={this.scrollContainerRef}>
                                        <Container className={classes.rootContainer} maxWidth={hasSidebarMenu() ? 'xl' : 'lg'}>
                                            <Card className={classes.card}>
                                                <CustomThemeContext.Consumer>
                                                    {({ logo, currentClient }) => getClientLogo(logo, currentClient)}
                                                </CustomThemeContext.Consumer>
                                                <AppBar position="sticky" className={classes.mainappbar}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        {isAuthenticated() && hasSidebarMenu() && <Hidden mdUp>
                                                            <Box flexShrink={1} alignSelf={'center'} sx={{ marginLeft: 1 }}>
                                                                <IconButton onClick={this.handleShowMenu}>
                                                                    <Menu />
                                                                </IconButton>
                                                            </Box>
                                                        </Hidden>}
                                                        <Box flexGrow={1} sx={{ maxWidth: '100%' }}>
                                                            <Grid container>
                                                                <Hidden mdDown>
                                                                    <Grid item md={9}>
                                                                        {isAuthenticated() && !hasSidebarMenu() && <Tabs
                                                                            variant="fullWidth"
                                                                            value={tab}
                                                                            textColor="inherit"
                                                                            indicatorColor="secondary"
                                                                            onChange={this.handleTabChange}
                                                                            aria-label="nav tabs example"
                                                                        >   
                                                                            {tabs.map(tabItem => {
                                                                                return <Tab key={'tab-button-desktop-'+tabItem} component={Link} to={"/"+tabItem} label={<ConfiguredCustomText fallback={t(tabItem)} section={tabItem} label={'title'}/>} className={classes.topTabMenu}/>
                                                                            })}
                                                                        </Tabs>}
                                                                    </Grid>
                                                                </Hidden>
                                                                <Grid item xs={12} md={3} textAlign={'right'}>
                                                                    {isAuthenticated() 
                                                                        ? <ProfileMenu />
                                                                        : <IconButton
                                                                            color="inherit"
                                                                            className={classes.profilebtn}
                                                                            size="large"
                                                                            component={Link}
                                                                            to={'/'}
                                                                            >
                                                                            <Typography>
                                                                                {t('login')}
                                                                            </Typography>
                                                                            <LoginIcon sx={{ marginLeft: 1 }}/>
                                                                        </IconButton>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </AppBar>
                                                <Box flexGrow={1} sx={{ display: 'flex', position: 'relative' }}>
                                                    {isAuthenticated() && hasSidebarMenu() && <React.Fragment>
                                                        <Hidden mdUp>
                                                            <Drawer
                                                                // container={container}
                                                                variant="temporary"
                                                                open={showMenu}
                                                                onClose={this.handleHideMenu}
                                                                ModalProps={{
                                                                    keepMounted: true, // Better open performance on mobile.
                                                                }}
                                                                sx={{
                                                                    display: { xs: 'block', md: 'none' },
                                                                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 300 },
                                                                }}
                                                            >
                                                                <Box>
                                                                    <AppBar position={'relative'}>
                                                                        <Toolbar>
                                                                            <Box flexGrow={1}>&nbsp;</Box>
                                                                            <Box flexShrink={1}>
                                                                                <IconButton onClick={this.handleHideMenu}>
                                                                                    <Close />
                                                                                </IconButton>
                                                                            </Box>
                                                                        </Toolbar>
                                                                    </AppBar>
                                                                    <List>
                                                                        {getMenuList(tabs, tab)}
                                                                    </List>
                                                                </Box>
                                                            </Drawer>
                                                        </Hidden>
                                                        <Hidden mdDown>
                                                            <Box  flexShrink={1} className={classes.sidebarMenu}>
                                                                <List ref={this.sidebarMenuRef}>
                                                                    {getMenuList(tabs, tab)}
                                                                </List>
                                                            </Box>
                                                            <Divider orientation="vertical" variant="middle" flexItem className={classes.sidebarMenuDivider}/>
                                                        </Hidden>
                                                    </React.Fragment>}
                                                    <Box flexGrow={1} sx={{ maxWidth: '100%', overflow: 'hidden' }}>
                                                        <main className={classes.content + ' ' + (WindowHelper.isIOSDevice() ? classes.contentIOS : '')}>
                                                            {children}
                                                            {isAuthenticated() && <Box className={classes.bottomInfos + ' ' + (!hasSidebarMenu() ? (WindowHelper.isIOSDevice() ? '' : classes.bottomInfosWithBar) : '')}>
                                                                <Box>
                                                                    <Typography variant="caption" color='secondary'>
                                                                        {<span className={classes.providerText}>{t('customer-portal-provided-by')} {client.name ?? ''}&nbsp;&nbsp;|</span>}<ButtonLink color='secondary' variant="caption" component={Button} underline="hover" onClick={this.handleShowDSGVO}>{t('DSGVO')}</ButtonLink>
                                                                    </Typography>
                                                                </Box>
                                                            </Box>}
                                                        </main>
                                                        <Hidden mdUp>
                                                            {isAuthenticated() && !hasSidebarMenu() && !isMobileKeyboardOpen && <AppBar position="fixed" style={{top: "auto", bottom: 0}}>
                                                                <BottomNavigation value={tab} onChange={this.handleTabChange} className={classes.bottomNavContainer}>
                                                                    {tabs.map((i,index) => getBottomNavigationItem(i))}
                                                                </BottomNavigation>
                                                            </AppBar>}
                                                        </Hidden>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        </Container>
                                    </Box>
                                </Box> 
                            )}
                        </CustomThemeContext.Consumer>                         
                    )}
                </AuthContext.Consumer>
            </Router>
        );
    }
}

Layout.contextType = AuthContext;


const  TranslatedComponent = withTranslation('translation')(Layout);
export default withStyles(useStyles)(TranslatedComponent);