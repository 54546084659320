import { Route } from "react-router-dom";
import Layout from "../Components/Unsecured/Layout";

function RouteLayoutMinimal ({ component: Component, componentProps = {}, ...rest }) {
    return (
        <Route
            {...rest}
            render={routeProps => (
                <Layout {...routeProps}>
                    <Component {...routeProps} {...componentProps}/>
                </Layout>
            )}
        />
    );
}

export default RouteLayoutMinimal;