import { Close } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import { Box } from '@mui/system';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import history from '../../Utility/RouterHistory';
import { AuthContext } from '../Contexts/AuthContext';
import BookingContextProvider, { BookingContext } from '../Contexts/BookingContext';
import BookingProcess from '../Elements/Booking/BookingProcess';
import Checkout from '../Elements/Booking/Checkout';
import Calendar from '../Elements/Calendar';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText';
import CustomFab from '../Elements/CustomFab';
import MainContent from '../Elements/MainContent';


const useStyles = (theme) => ({

});

class AppointmentBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
        };
    }
    

    componentDidMount() {
        this.checkIfBookingIsEnabled();
    }

    checkIfBookingIsEnabled = () => {
        const { currentPublicClientConfigurations } = this.context;
        
        if (currentPublicClientConfigurations) {
            if (!(currentPublicClientConfigurations.booking ?? true)) {
                history.push('/');
            }
        }

        this.setState({
            mounted: true,
        });
    } 
    
    render() {
        const { t, classes } = this.props;
        const { mounted } = this.state;

        const { deeplink } = this.props.match.params;
        
        const getHeaderAction = (stage) => {
            if (!['checkout'].includes(stage)) {
                return <Box flexShrink={1} alignSelf="center">
                    <CustomFab
                        color="secondary"
                        component={Link}
                        to="/appointments"
                    >
                        <Close className={classes.extendedIcon} />
                        {t('cancel')}
                    </CustomFab>
                </Box>;
            }
        }

        return (
            <BookingContextProvider initialStage={deeplink} selectedClientNumber={this.props.match.params.clientNumber ?? null}>
                <AuthContext.Consumer>
                    {({ isAuthenticated }) => (
                        <BookingContext.Consumer>
                            {({ stage }) => (
                                <React.Fragment>
                                    {mounted && <MainContent
                                        title={<ConfiguredCustomText fallback={t('book-appointment')} section={'bookings'} label={'title'}/>}
                                        textPrimary={<ConfiguredCustomText fallback={t('easy-and-uncomplicated')} section={'bookings'} label={'primary'}/>}
                                        textSecondary={<ConfiguredCustomText section={'bookings'} label={'secondary'}/>}
                                        marginBottomHead={0}
                                        actionComponent={isAuthenticated() && getHeaderAction(stage)}
                                    >
                                        {!['calendar', 'checkout'].includes(stage) && <BookingProcess />}
                                        {stage === 'calendar' && <Calendar />}
                                        {stage === 'checkout' && <Checkout />}
                                    </MainContent>}
                                </React.Fragment>
                            )}
                        </BookingContext.Consumer>
                    )}
                </AuthContext.Consumer>
            </BookingContextProvider>
        );
    }
}
AppointmentBooking.contextType=AuthContext;

const  TranslatedComponent = withTranslation('translation')(AppointmentBooking);
export default withStyles(useStyles, { withTheme: true })(TranslatedComponent);