
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';
import {
    Box,
    Button, CircularProgress, Grid,
    IconButton, InputAdornment, Link,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Events from '../../Utility/Events.js';
import { AuthContext } from '../Contexts/AuthContext.js';
import { CustomThemeContext } from '../Contexts/CustomThemeContext.js';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText';
import { PersonAdd } from '@mui/icons-material';
import { Login } from '@mui/icons-material';


const useStyles = (theme) => ({
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
    },
    spacer: {
        marginTop: theme.spacing(1),
    },
    input: {
        width: '100%',
    },
    spaceActions: {
        marginTop: theme.spacing(4),
    }
});

class LoginControl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mounted: true,
            username: "",
            usernameError: undefined,
            password: "",
            passwordError: undefined,
            loading: false,
            showPassword: false
        };

        this.pwdRef = React.createRef();
    }

    componentDidMount() {
        if (localStorage.getItem('last-username')) {
            this.setState({
                username: localStorage.getItem('last-username')
            });
            if (this.pwdRef && this.pwdRef.current) {
                this.pwdRef.current.focus();
            }
        }
    }
    
    handleOnFocus = (e) => {
        const target = e.target;
        if (target) {
            setTimeout(() => {
                target.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }, 250);
        }
    }

    handleLogin = (e, loginMethod) => {
        e.preventDefault();

        const { t } = this.props;
        const { username, password } = this.state;

        if (username !== "" && password !== "") {
            localStorage.setItem('last-username', username);
            this.setState({
                usernameError: undefined,
                passwordError: undefined
            });
            Events.dispatch('startLoading');
            loginMethod({
                username: username,
                password: password,
            })
            .then(res => {
                Events.dispatch('doneLoading');
                Events.dispatch('msgSuccess', t('successfull-login'));
            })
            .catch(err => {
                this.setState({
                    usernameError: true,
                    passwordError: true
                });
                Events.dispatch('doneLoading');
            });
        } else {
            if (username === "") {
                this.setState({
                    usernameError: true,
                });
            }
            
            if (password === "") {
                this.setState({
                    passwordError: true
                });
            }
        }
    }

    handleChange (e) {
        const name = e.target.name;
        let value = e.target.value;

        if (name === 'username') {
            value = value.trim();
        }

        this.setState({
            [name]: value
        });
    }
    
    handleClickShowPassword(e) {
        this.setState({
            showPassword: !this.state.showPassword
        });
    }

    render() {
        const { t, classes } = this.props;
        const values = this.state;

        const { mounted, usernameError, passwordError, username } = this.state;

        const getRegistrationLink = () => {
            return <Link
                to="/register"
                component={RouterLink}
                variant="subtitle2"
                color="secondary"
                underline="hover">
                    <Button startIcon={<PersonAdd />} color={'secondary'}>
                        {t('register')}
                    </Button>
            </Link>;
        }

        return (
            <AuthContext.Consumer>
                {({ login }) => (
                    <React.Fragment>
                    {!mounted && <Box textAlign='center' marginTop={15}><CircularProgress size={150}/></Box>}
                    {mounted && <form
                            onSubmit={(e) => this.handleLogin(e, login)} 
                            className={classes.form} 
                            noValidate
                        >
                            <CustomThemeContext.Consumer>
                                {({ currentClient, currentPublicClientConfigurations }) => (
                                    <Box marginBottom={2}>
                                        <Tabs value={'login'}>
                                            <Tab value={'login'} sx={{ padding: 0, flexBasis: '50%' }} label={
                                                <Button startIcon={<Login />} color={'primary'}>
                                                    {t('login')}
                                                </Button>
                                            }/>
                                            {(currentClient && (currentPublicClientConfigurations.registration ?? false)) ? <Tab value={'register'} sx={{ padding: 0, flexBasis: '50%' }} label={getRegistrationLink()}/> : null}
                                        </Tabs>
                                    </Box>
                                )}
                            </CustomThemeContext.Consumer>
                            <Box style={{ flexGrow: 1 }}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="username"
                                    name="username"
                                    label={t('email')}
                                    onChange={(e) => this.handleChange(e)}
                                    onFocus={this.handleOnFocus}
                                    autoComplete="email"
                                    value={username}
                                    error={usernameError}
                                    className={usernameError ? 'shake' : ''}
                                    autoFocus
                                />
                                <TextField 
                                    inputRef={this.pwdRef}
                                    margin="normal"
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label={t('password')}
                                    error={passwordError}
                                    className={usernameError ? 'shake' : ''}
                                    // autoComplete="current-password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    onChange={(e) => this.handleChange(e)}
                                    onFocus={this.handleOnFocus}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    // onMouseDown={handleMouseDownPassword}
                                                    onClick={() => this.handleClickShowPassword()}
                                                    size="large">
                                                {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <Grid container className={classes.spacer}>
                                    <Grid item xs></Grid>
                                    <Grid item>
                                        <Link
                                            to='/password-request'
                                            component={RouterLink}
                                            variant="caption"
                                            underline="hover">
                                            {t('password-forgot')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                            
                            <CustomThemeContext.Consumer>
                                {({ currentClient, currentPublicClientConfigurations, isMobileKeyboardOpen }) => (
                                    <Box className={(isMobileKeyboardOpen && classes.spaceActions)}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            disabled={values.username === '' || values.password === '' ? true : false}
                                            className={classes.submit}
                                        >
                                            {this.state.loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            {t('login')}
                                        </Button>
                                        <Grid container className={classes.spacer}>
                                            <Grid item xs></Grid>
                                            <Grid item>
                                                <span>&nbsp;</span>
                                            </Grid>
                                            <Grid item xs></Grid>
                                        </Grid>
                                    </Box>
                                )}
                            </CustomThemeContext.Consumer>
                        </form>}     
                    </React.Fragment>
                )}
            </AuthContext.Consumer>
        );
    }
}

LoginControl.contextType=CustomThemeContext;

const  TranslatedLoginControl = withTranslation('translation')(LoginControl);
export default withStyles(useStyles)(TranslatedLoginControl);